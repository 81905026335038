@font-face {
  font-family: 'ProximaNova-Light';
  font-display: swap;
  src: url('/assets/fonts/proxima-nova-light.eot');
  src: url('/assets/fonts/proxima-nova-light.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/proxima-nova-light.woff2') format('woff2'), url('/assets/fonts/proxima-nova-light.woff') format('woff'), url('/assets/fonts/proxima-nova-light.ttf') format('truetype');
}

@font-face {
  font-family: 'ProximaNova-Regular';
  font-display: swap;
  src: url('/assets/fonts/proxima-nova-regular.eot');
  src: url('/assets/fonts/proxima-nova-regular.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/proxima-nova-regular.woff2') format('woff2'), url('/assets/fonts/proxima-nova-regular.woff') format('woff'), url('/assets/fonts/proxima-nova-regular.ttf') format('truetype');
}

@font-face {
  font-family: 'ProximaNova-Medium';
  font-display: swap;
  src: url('/assets/fonts/proxima-nova-medium.eot');
  src: url('/assets/fonts/proxima-nova-medium.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/proxima-nova-medium.woff2') format('woff2'), url('/assets/fonts/proxima-nova-medium.woff') format('woff'), url('/assets/fonts/proxima-nova-medium.ttf') format('truetype');
}

@font-face {
  font-family: 'ProximaNova-Semibold';
  font-display: swap;
  src: url('/assets/fonts/proxima-nova-semibold.eot');
  src: url('/assets/fonts/proxima-nova-semibold.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/proxima-nova-semibold.woff2') format('woff2'), url('/assets/fonts/proxima-nova-semibold.woff') format('woff'), url('/assets/fonts/proxima-nova-semibold.ttf') format('truetype');
}

@font-face {
  font-family: 'ProximaNova-Bold';
  font-display: swap;
  src: url('/assets/fonts/proxima-nova-bold.eot');
  src: url('/assets/fonts/proxima-nova-bold.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/proxima-nova-bold.woff2') format('woff2'), url('/assets/fonts/proxima-nova-bold.woff') format('woff'), url('/assets/fonts/proxima-nova-bold.ttf') format('truetype');
}

@font-face {
  font-family: 'ProximaNova-Black';
  font-display: swap;
  src: url('/assets/fonts/proxima-nova-black.eot');
  src: url('/assets/fonts/proxima-nova-black.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/proxima-nova-black.woff2') format('woff2'), url('/assets/fonts/proxima-nova-black.woff') format('woff'), url('/assets/fonts/proxima-nova-black.ttf') format('truetype');
}
