$spaces: 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100;

@each $space in $spaces {
  .mt-#{$space} {
    margin-top: #{$space}px !important;
  }

  .mt-#{$space}-negative {
    margin-top: -#{$space}px !important;
  }

  .mb-#{$space} {
    margin-bottom: #{$space}px !important;
  }

  .pt-#{$space} {
    padding-top: #{$space}px !important;
  }

  .pb-#{$space} {
    padding-bottom: #{$space}px !important;
  }
}

.no-mt {
  margin-top: 0 !important;
}

.no-mb {
  margin-bottom: 0 !important;
}

.no-pt {
  padding-top: 0 !important;
}

.no-pb {
  padding-bottom: 0 !important;
}


