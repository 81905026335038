.cdk-overlay-dark-backdrop {
  background-color: rgba(0, 0, 0, 0.7);
}

.mat-dialog {
  position: relative;

  &-container {
    // display: flex;
    // flex-direction: column;
    padding: 0;
    @include rem(padding-left, 40px);
    @include rem(padding-right, 40px);
    position: relative;
    min-height: 1px;
    height: 100%;
    border-radius: 0px;

    @include for-tablet-portrait-up {
      @include rem(padding-left, 50px);
      @include rem(padding-right, 50px);
    }
  }

  &-close {
    display: block;
    background: transparent;
    border: none;
    margin: 0;
    padding: 0;
    position: absolute;
    @include rem(top, 20px);
    @include rem(right, 20px);
    cursor: pointer;

    .mat-icon {
      width: auto;
      height: auto;
    }

    svg {
      //color: $color-blue;
      @include themify($themes) {
        color: themed('color-secondary');
      }
      display: block;
      @include rem(width, 16px);
      @include rem(height, 16px);
    }

    &:hover {
      background: transparent !important;
      svg {
        //color: $color-dark-blue;
        @include themify($themes) {
          color: themed('color-brand');
        }
      }
    }

    &:focus {
      background: transparent !important;
      box-shadow: none;
    }
  }

  &-header {
    flex: 1 0 auto;
    min-width: 1px;
    min-height: 1px;
    padding: 0;
    margin: 0;
    @include rem(margin-bottom, 15px);
    @include rem(padding-top, 40px);

    h2 {
      display: block;
      @include font(20px, 22px, 4px);
      font-family: $font-bold;
      font-weight: normal;
      text-transform: uppercase;

      & + hr {
        @include rem(margin-top, 10px);
      }

      @include for-phone-only {
        @include font(16px, 22px, 4px);
      }

      @include for-tablet-portrait-up {
        @include font(20px, 22px, 4px);
      }
    }

    hr {
      display: block;
      border: none;
      background-color: transparent;
      height: 1px;
      border-bottom: 1px dashed $color-silver-chalice;
      margin: 0;
    }

    small {
      display: block;
      text-align: right;
      @include font(12px, 12px, 2.4px);
      font-family: $font-bold;
      font-weight: normal;
      text-transform: uppercase;
      @include rem(margin-top, 10px);

      @include for-tablet-portrait-up {
        @include font(8px, 8px, 1.6px);
      }
    }


    @include for-tablet-portrait-up {
      @include rem(padding-top, 50px);
    }
  }

  &-body {
    flex: 1 1 auto;
    min-width: 1px;
    min-height: 1px;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .mat-dialog-content {
      height: 100%;
    }

    p {
      margin: 0;
    }

    &:last-child {
      @include rem(padding-bottom, 40px);
      @include for-tablet-portrait-up {
        @include rem(padding-bottom, 50px);
      }
    }
  }

  &-footer {
    flex: 1 0 auto;
    min-width: 1px;
    min-height: 1px;
    @include rem(padding, 25px 0 0 0);
    margin: 0;

    &:last-child {
      @include rem(padding-bottom, 40px);

      @include for-tablet-portrait-up {
        @include rem(padding-bottom, 50px);
      }
    }
  }
}
