.mat-paginator {
  width: 100%;
  background-color: transparent;

  .mat-select {
    order: 1;
  }

  &.dark-theme {
    .mat-select {
      background-color: $color-medium-grey;
      border: 1px solid $color-medium-grey;
    }
    .mat-paginator-navigation-previous,
    .mat-paginator-navigation-next {
      &[disabled] {
        background-color: $color-medium-grey;
        color: $color-white;
      }
    }
  }
}

.mat-paginator-container {
  min-height: 0;
  padding: 0;
  justify-content: center;
  flex-wrap: wrap;
}

.mat-paginator-page-size {
  flex-direction: column;
  align-items: center;
  margin: 0;
  width: 100%;

  @include for-tablet-portrait-up {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: auto;
  }
}

.mat-paginator-page-size-label {
  display: none;
  //color: $color-deep-blue;
  @include themify($themes) {
    color: themed('color-primary');
  }
  @include rem(font-size, 16px);
  font-family: $font-bold;
  white-space: nowrap;
  letter-spacing: 1.3px;

  @include for-tablet-portrait-up {
    display: block;
    @include rem(margin, 0 10px);
  }

  @include for-tablet-landscape-up {
    @include rem(font-size, 13px);
  }
}

.mat-paginator-page-size-select {
  margin: 0;
  width: 100%;

  .mat-select-value-text {
    @include font(16px, 1em, 0.1em);

    @include for-tablet-landscape-up {
      @include font(13px, 1em, 0.1em);
    }
  }

  .mat-select-placeholder,
  .mat-select-value-text span {
    //color: $color-deep-blue;
    @include themify($themes) {
      color: themed('color-primary');
    }
    &:before {
      content: 'Items per page: ';
      font-family: $font-bold;

      @include for-tablet-portrait-up {
        display: none;
      }
    }
  }

  .mat-form-field-wrapper,
  .mat-form-field-infix,
  .mat-form-field-subscript-wrapper {
    border: 0;
    margin: 0;
    padding: 0;
  }

  @include for-tablet-portrait-up {
    @include rem(width, 73.5px);
  }
}

.mat-paginator-range-actions {
  align-items: center;
  justify-content: center;
  min-height: 0;
  @include rem(margin-top, 30px);

  @include for-tablet-portrait-up {
    justify-content: flex-start;
    margin-top: 0;
  }
}

.mat-paginator-range-label {
  //color: $color-deep-blue;
  @include themify($themes) {
    color: themed('color-primary');
  }
  font-family: $font-bold;
  @include rem(font-size, 16px);
  @include rem(margin, 0 15px);
  line-height: 1;
  letter-spacing: 1.3px;

  @include for-tablet-landscape-up {
    @include rem(font-size, 13px);
  }
}

.mat-paginator-navigation-previous,
.mat-paginator-navigation-next {
  //background-color: $color-deep-blue;
  @include themify($themes) {
    background-color: themed('color-primary');
  }
  color: $color-white;
  border-radius: 50%;
  @include rem(line-height, 30px);
  @include rem(width, 30px);
  @include rem(height, 30px);
  position: relative;

  &[disabled] {
    background-color: $color-light-grey;
    color: $color-white;
  }

  @include for-tablet-landscape-up {
    @include rem(width, 16px);
    @include rem(height, 16px);
  }
}

.mat-paginator-navigation-previous {
  order: -1;

  @include for-tablet-portrait-up {
    order: 0;
  }
}

.mat-paginator-navigation-next {
  @include for-tablet-portrait-up {
    @include rem(margin-left, 15px);
  }

  @include for-tablet-landscape-up {
    @include rem(margin-left, 12px);
  }
}

.mat-paginator-icon {
  fill: currentColor;
  @include rem(width, 40px);
  @include rem(height, 40px);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @include for-tablet-landscape-up {
    @include rem(width, 20px);
    @include rem(height, 20px);
  }
}
