@mixin for-phone-small-only {
  @media (max-width: 340px) { @content; }
}

@mixin for-phone-only {
  @media (max-width: 599px) { @content; }
}

@mixin for-tablet-portrait-up {
  @media (min-width: 600px) { @content; }
}

@mixin for-tablet-768 {
  @media (max-width: 768px) { @content; }
}

@mixin for-tablet-landscape-down {
  @media (max-width: 899px) { @content; }
}

@mixin for-tablet-landscape-up {
  @media (min-width: 900px) { @content; }
}

@mixin for-tablet-only {
  @media (max-width: 1199px) { @content; }
}

@mixin for-desktop-up {
  @media (min-width: 1200px) { @content; }
}

@mixin for-big-desktop-up {
  @media (min-width: 1800px) { @content; }
}

@mixin onSpecific($width, $height) {
    @media (max-width: $width) and (max-height: $height) { @content; }
}
