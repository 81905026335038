.resource-section-links {
  .resource-list-links {
    @include rem(padding, 35px 0 80px);

    @include for-desktop-up {
      @include rem(padding-bottom, 90px);
    }
  }

  .resource-section-links__title + .resource-list-links {
    @include rem(padding-top, 25px);

    @include for-desktop-up {
      @include rem(padding-top, 35px);
    }
  }
}

.resource-section-links__title {
  //color: $color-deep-blue;
  @include themify($themes) {
    color: themed('color-primary');
  }
  font-family: $font-bold;
  @include rem(font-size, 20px);
  display: block;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  margin: 0;
  @include rem(padding, 36px 0 0);

  @include for-tablet-portrait-up {}

  @include for-desktop-up {}
}

.resource-list-links + .resource-section-links__title {
  padding-top: 0;
}
