.data-grid {
  &-row {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: flex-start;

    & + .data-grid-row {
      @include rem(padding-top, 22px);

      @include for-tablet-portrait-up {
          @include rem(padding-top, 23px);
      }
    }

    .data-grid-item {
      min-width: 0;
      flex-grow: 1;
      flex-basis: 0;
    }
  }

  &-item-half {
    display: inline-block;
    width: calc(50% - 4px);
  }

  &-title,
  &-value {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    letter-spacing: 0.1em;
  }
  &-value-success-msg {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    letter-spacing: 0.1em;
    //color: $color-green;
    @include themify($themes) {
      color: themed('color-success');
    }
  }

  &-title {
    @extend .h5;
  }

  &-value {
    @extend .h4;
  }
}
