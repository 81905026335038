.nav-widget {
  background-color: $color-white;
  //color: $color-deep-blue;
  @include themify($themes) {
    color: themed('color-primary');
  }
  display: flex;
  justify-content: stretch;
  //border-bottom: 5px solid $color-blue;
  @include themify($themes) {
    border-bottom: themed('navWidgetBorderBottom');
  }
  position: relative;

  &__menu {
    display: none;
    background: #ececec url('/assets/images/backgrounds/bg-pattern_light-gray.jpg') repeat;
    background-size: 8px;
    //color: $color-deep-blue;
    @include themify($themes) {
      color: themed('color-primary');
    }
    @include rem(padding, 50px);
    box-sizing: border-box;
    float: left;

    @include for-desktop-up {
      display: block;
      @include rem(flex-basis, 267px);
      @include rem(max-width, 267px);
    }

    > span {
      display: block;
      @include font(13px, 1em, 2.6px);
      font-family: $font-bold;
      text-transform: uppercase;
      @include rem(margin-bottom, 20px);
    }

    > a + span {
      @include rem(margin-top, 36px);
    }

    a {
      display: block;
      @include font(11px, 15px, 2.2px);
      //color: $color-deep-blue;
      @include themify($themes) {
        color: themed('color-primary');
      }
      font-family: $font-medium;
      text-transform: uppercase;
      text-decoration: none;

      &:hover, &.is-active {
        //color: $color-blue;
        @include themify($themes) {
          color: themed('color-secondary');
        }
        font-family: $font-bold;
        position: relative;

        &:before {
          content: '';
          position: absolute;
          top: 50%;
          @include rem(left, -18px);
          transform: translateY(-50%);
          width: 0;
          height: 0;
          border-style: solid;
          @include rem(border-width, 6.75px 0 6.75px 6.75px);
          border-color: transparent transparent transparent currentColor;
        }
      }
      & + a {
        @include rem(margin-top, 20px);
      }
    }
  }

  &__content {
    //color: $color-deep-blue;
    @include themify($themes) {
      color: themed('color-primary');
    }
    @include rem(padding, 45px 40px 62.5px);
    height: 100%;
    box-sizing: border-box;
    overflow: hidden;
    width: 100%;

    @include for-desktop-up {
      @include rem(padding-left, 50px);
      @include rem(padding-right, 50px);
      @include rem(flex-basis, 873px);
      @include rem(max-width, 873px);
      width: auto;
    }
  }
}
