@import 'config/variables';
@import 'mixins';

.offers-dialog {
  max-width: 90% !important;

  @include for-desktop-up {
    max-width: 750px !important;
  }

  .mat-dialog-container {
    @include for-phone-only {
      max-height: 90vh;
    }
  }

  .mat-dialog-body {
    @include for-tablet-portrait-up {
      @include rem(padding-top, 4px)
    }
  }

  .mat-dialog-footer {
    @include for-tablet-portrait-up {
      @include rem(padding-top, 40px);
    }

    & > .btn,
    & > .mat-button {
      max-width: 100%;

      @include for-tablet-portrait-up {
        width: auto;
        max-width: 0;
        @include rem(min-width, 255px);
      }
    }
  }

  .offers-dialog__feedback {
    .mat-dialog-header {
      h2 {
        @include rem(margin-bottom, 7px);

        @include for-tablet-portrait-up {
          @include rem(margin-bottom, 5px);
        }
      }
    }

    .mat-dialog-body {
      p {
        margin: 0;
        padding: 0;
      }

      p + p {
        @include rem(margin-top, 24px);
      }
    }
  }
}
