.mat-tooltip {
  //background-color: $color-deep-blue;
  @include themify($themes) {
    background-color: themed('color-primary');
  }
  font-family: $font-semibold;
  @include rem(font-size, 12px);
  letter-spacing: 0.1em;
}

.multiline-tooltip{
  white-space: pre-line;
  font-size: 10px;
  font-weight: normal;
  @include themify($themes) {
    background-color: themed('color-primary');
  }
}
