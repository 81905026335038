%reset-material-styles {
  padding: 0;
  margin: 0;
  border-radius: 0px;

  p {
    margin: 0;
  }

  P + p {
    @include rem(margin-top, 13px);
  }

  .col, .row {
    margin-bottom: 0;
  }
}

%card-gutters {
  @include rem(padding, 40px);

  @include for-phone-small-only {
    @include rem(padding, 40px 20px);
  }

  @include for-tablet-landscape-up {
    @include rem(padding, 50px);
  }
}

%card-negative-gutters {
  @include rem(margin-left, -40px);
  @include rem(margin-right, -40px);

  @include for-tablet-landscape-up {
    @include rem(margin-left, -50px);
    @include rem(margin-right, -50px);
  }
}

.mat-card {
  @extend %reset-material-styles;
  @extend %card-gutters;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow: hidden;
  box-shadow: none !important;
  height: 100%;

  &-header,
  &-header-text,
  &-title,
  &-subtitle,
  &-content,
  &-footer,
  &-actions {
    @extend %reset-material-styles;
  }

  &-header {
    //border-bottom: 1px dashed $color-silver-chalice;
    @include themify($themes) {
      background-color: themed('headerBorderBottom');
    }
    @include rem(margin-bottom, 7px);

    &.no-divider {
      border: none;
    }
  }

  &-title {
    @extend .h2;
    //color: $color-deep-blue;
    @include themify($themes) {
      color: themed('color-primary');
    }
    @include rem(padding-bottom, 5px);

    & + .mat-card-subtitle {
      @include rem(padding-top, 15px);
    }

    @include for-tablet-landscape-up {
      @include rem(padding-bottom, 10px);
    }
  }

  &-subtitle {
    margin: 0;
    padding: 0;
    @include rem(padding, 10px 0 15px 0);
    @include font(16px, 24px, 1.3px);
    font-family: $font-regular;
    //color: $color-deep-blue;
    @include themify($themes) {
      color: themed('color-primary');
    }

    &:not(:first-child) {
      margin: 0;
    }

    @include for-tablet-landscape-up {
      @include font(13px, 20px, 1.3px);
    }
  }

  &-content {
    @extend %card-negative-gutters;
    min-height: 1px;
    flex: auto;

    .container {
      @include rem(padding-left, 40px);
      @include rem(padding-right, 40px);

      @include for-tablet-landscape-up {
        @include rem(padding-left, 50px);
        @include rem(padding-right, 50px);
      }
    }

    .ext-link {
      display: inline;
      @include for-tablet-landscape-up {
        position: absolute;
        margin: 0 !important;
        @include rem(top, 55px);
        @include rem(right, 50px);
      }
    }
  }

  &-actions {
    @include rem(margin-top, 30px);
    text-align: center;

    &:last-child {
      @include rem(margin-top, 30px);
      margin-bottom: 0 !important;
    }
  }

  &-footer {
    @include rem(margin-top, 30px);
    @include rem(padding-top, 28px);
    //border-top: 1px dashed $color-silver-chalice;
    @include themify($themes) {
      color: themed('color-primary');
    }
    text-align: center;

    &.no-divider {
      margin-top: 0;
      border: none;
    }
  }

  &--widget {
    //border-bottom: 5px solid $color-blue;
    @include themify($themes) {
      border-bottom: themed('widgetBorderBottom');
    }
    .mat-card-header {
      @include rem(margin-bottom, 22px);
    }
  }

  &--widget-details {
    @include themify($themes) {
      background: themed('color-medium-grey');
    }
    border-style: dashed;
    .mat-card-header {
      @include rem(margin-bottom, 22px);
    }
  }

  &--widget-order {
    @include themify($themes) {
      //background: themed('color-medium-grey');
    }
    border-style: dashed;
    .mat-card-header-text {
      width: 100%;
      text-align: center;
      margin: 0px!important;
    }
    .mat-card-header {
      @include rem(margin-bottom, 22px);
    }
  }
}
