h1, h2, h3, h4, h5, h6, b, strong {
  margin-top: 0;
  font-family: $font-bold;
  font-weight: normal;
}

h1, .h1 {
  @include font(26px, 26px, 5.2px);
  font-family: $font-bold;
  font-weight: normal;
  text-transform: uppercase;

  @include for-tablet-portrait-up {
    @include font(30px, 32px, 6px);
  }
}

h2, .h2 {
  @include font(20px, 22px, 4px);
  font-family: $font-bold;
  font-weight: normal;
  text-transform: uppercase;

  @include for-phone-small-only {
    @include font(16px, 22px, 4px);
  }
}

h3, .h3 {
  @include font(16px, 22px, 1.6px);
  font-family: $font-bold;
  font-weight: normal;
  //color: $color-blue;
  @include themify($themes) {
    color: themed('color-secondary');
  }
  text-transform: uppercase;
}

h4, .h4 {
  @include font(17px, 22px, 1.6px);
  //color: $color-deep-blue;
  @include themify($themes) {
    color: themed('color-primary');
  }
  font-family: $font-medium;
  font-weight: normal;

  @include for-tablet-portrait-up {
    @include font(16px, 22px, 1.6px);
  }
}

h5, .h5 {
  @include font(13px, 22px, 1.2px);
  //color: $color-grey;
  @include themify($themes) {
    color: themed('color-grey');
  }
  font-family: $font-medium;
  font-weight: normal;
  text-transform: uppercase;

  @include for-tablet-portrait-up {
    @include font(12px, 22px, 1.2px);
  }
}

h6, .h6 {
  @include font(16px, 22px, 1.6px);
  //color: $color-blue;
  @include themify($themes) {
    color: themed('color-secondary');
  }
  font-family: $font-bold;
  font-weight: normal;
  text-transform: uppercase;
  @include rem(margin-bottom, 15px);

  @include for-tablet-portrait-up {
    @include font(16px, 22px, 1.6px);
  }
}

p, .text {
  @include font(16px, 24px, 1.3px);
  font-family: $font-regular;
  color: $color-deep-blue;

  @include for-tablet-landscape-up {
    @include font(13px, 20px, 1.3px);
  }
}

small, .text-small {
  @include font(12px, 14px, 1.3px);

  @include for-tablet-portrait-up {
    @include font(10px, 12px, 1.3px);
  }
}

a {
  @extend .text;
  //color: $color-blue;
  @include themify($themes) {
    color: themed('color-secondary');
  }
  text-decoration: none;

  @include for-tablet-landscape-up {
    &:hover {
      //color: $color-dark-blue;
      @include themify($themes) {
        color: themed('color-brand');
      }
    }
  }
}

.text-upper {
  text-transform: uppercase;
}

.text-bold {
  font-family: $font-bold;
  font-weight: normal;
}

.text-semibold {
  font-family: $font-medium;
  font-weight: normal;
}

.text-block {
  display: block;
}

.text-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
}

.text-align-left {
  text-align: left;
}

.text-warn {
  color: $color-red;
}
