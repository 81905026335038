.form-rules {
  &-title {
    display: block;
    @include rem(font-size, 13px);
    @include rem(letter-spacing, 1.45px);
    margin: 0;
    padding: 0;
  }

  &-list {
    margin: 0;
    list-style: none;
    @include rem(padding, 10px 0 0);
    @include rem(font-size, 13px);

    @include for-tablet-landscape-up {
      @include rem(padding-top, 13px);
    }

    li {
      @include rem(font-size, 13px);
      @include rem(letter-spacing, 1.45px);
      line-height: 1.5;
      @include rem(padding-left, 15px);
      @include rem(padding-bottom, 1px);
      position: relative;

      &:before {
        content: '-';
        @include rem(margin-right, 8px);
        position: absolute;
        left: 0;
      }

      & + li {
        margin: 0;
      }
    }
  }
}
