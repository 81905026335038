.form-with-validation {
  &-title {
    border-bottom: 1px dashed $color-silver-chalice;
    //color: $color-deep-blue;
    @include themify($themes) {
      color: themed('color-primary');
    }
    @include rem(font-size, 18px);
    @include rem(letter-spacing, 2px);
    font-family: $font-bold;
    text-transform: uppercase;
    @include rem(padding, 0 0 10px);
    display: block;
    margin: 0;
  }

  &-small {
    display: block;
    @include rem(font-size, 12px);
    font-family: $font-bold;
    @include rem(letter-spacing, 2.5px);
    text-transform: uppercase;
    text-align: right;
    @include rem(padding-top, 9px);

    @include for-tablet-landscape-up {
      @include rem(font-size, 8px);
      @include rem(letter-spacing, 1.7px);
      @include rem(padding-top, 5px);
    }
  }

  &-form {
    @include rem(padding-top, 30px);

    @include for-tablet-portrait-up {
      @include rem(padding-top, 25px);
    }

    @include for-tablet-landscape-up {
      @include rem(padding-top, 20px);
    }
  }

  &-validation {
    @include rem(padding-top, 10px);

    @include for-tablet-landscape-up {
      @include rem(padding-top, 12px);
    }
  }
}
