.resource-content-lists + .resource-content-lists {
  @include rem(margin-top, 49px);
}

.resource-content-lists__title {
  //color: $color-deep-blue;
  @include themify($themes) {
    color: themed('color-primary');
  }
  font-family: $font-bold;
  @include rem(font-size, 20px);
  display: block;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  margin: 0;
  @include rem(padding, 0 0 20px);
}

.resource-content-lists__subtitle {
  //color: $color-deep-blue;
  @include themify($themes) {
    color: themed('color-primary');
  }
  font-family: $font-bold;
  @include rem(font-size, 16px);
  display: block;
  letter-spacing: 0.1em;
  margin: 0;
  @include rem(padding, 0 0 0px);

  @include for-tablet-landscape-up {
    @include rem(font-size, 13px);
  }
}

.resource-content-lists__list {
  list-style: none;
  margin: 0;
  @include rem(padding, 0 0 30px);

  li {
    @include rem(font-size, 16px);
    letter-spacing: 0.1em;
    @include rem(padding-left, 15px);
    position: relative;
    @include rem(margin, 16px 0 0);
    @include rem(padding, 0 0 0 15px);

    @include for-tablet-landscape-up {
      @include rem(font-size, 13px);
    }

    &:before {
      background-color: currentColor;
      border-radius: 100%;
      content: '';
      @include rem(width, 3px);
      @include rem(height, 3px);
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-75%);
    }
  }
}

.resource-content-lists__link {
  display: inline-block;
}
