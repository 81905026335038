.resources-small {
  @include rem(margin-top, 85px);
}

.resources-small__text {
  //color: $color-deep-blue;
  @include themify($themes) {
    color: themed('color-primary');
  }
  @include rem(font-size, 11px);
  line-height: 1.8;
  display: block;
  letter-spacing: 0.1em;
  margin: 0;
  padding: 0;
}

.resources-small__text + .resources-small__text {
  @include rem(margin-top, 13px);
}
