// 1. Typography
// ==========================================================================

$font-base-size: 16px;
$font-light: 'ProximaNova-Light', sans-serif;
$font-regular: 'ProximaNova-Regular', sans-serif;
$font-medium: 'ProximaNova-Medium', sans-serif;
$font-semibold: 'ProximaNova-Semibold', sans-serif;
$font-bold: 'ProximaNova-Bold', sans-serif;
$font-black: 'ProximaNova-Black', sans-serif;

// 2. Colors
// ==========================================================================

$color-white: #fff;
$color-off-white: #fafafa;
$color-black: #000;
$color-alabaster: #f7f7f7;
$color-gallery: #ececec;

$color-lighter-grey: #ededed;
$color-light-grey: #eaeaea;
$color-grey: #9b9b9b;
$color-medium-grey: #dbdada;
$color-alto: #d8d8d8;
$color-porcelain: #eff4f4;
$color-silver-chalice: #b2b2b2;

$color-light-blue: #3db5e6;
$color-off-blue: #1188ca;
$color-blue: #0082ca;
$color-dark-blue: #085fa2;
$color-deep-blue: #17212f;

$color-christine: #f46a0e;
$color-rich-gold: #a04407;
$color-dark-orange: #d0500b;

$color-purple: #86339a;
$color-red: #e62e53;
$color-dark-red: #b11031;

$color-green: #23b882;
$color-mountain-meadow: #23b882;

$color-light-yellow: #fff2c7;
$color-yellow: #ebb400;

$color-fd-primary: #434849;
$color-fd-secondary: #707375;
$color-fd-secondary-imp: #707375;
$color-fd-disabled: #a6aaad;
$color-fd-background: #e5eaed;

// background colors
$color-fd-bg-primary: #e5eaed;
$color-fd-bg-medium: #d9dee1;
$color-fd-bg-light: #eff3f4;
$color-fd-bg-dark: #ebeff0;

// Framedream TENANT brand colors
$color-fd-brand: #13a085;
$color-fd-light-brand: #13a085;
$color-fd-dark-brand: #13a085;

// ECE TENANT brand colors
$color-ece-brand: #1c4570;
$color-ece-dark-brand: #ba9127;

// Galaxy TENANT brand colors
$color-gxly-brand: #152384;
$color-gxly-dark-brand: #152384;

// Perc TENANT brand colors
$color-perc-brand: #1f0f36;
$color-perc-dark-brand: #1f0f36;

// FOF TENANT brand colors
$color-fof-brand: #c99700;
$color-fof-dark-brand: #c99700;

// KPLR TENANT brand colors
$color-kplr-brand: #4d4d4f;
$color-kplr-dark-brand: #4d4d4f;
$color-kplr-primary: #434849;
$color-kplr-secondary: #a2d45e;

// 4. Grid
// ==========================================================================

$num-cols: 12 !default;
$container-gutter: 20px !default;
$container-gutter-medium: 32px !default;
$container-gutter-large: 30px !default;
$gutter-width: 16px !default;
$gutter-width-medium: 16px !default;
$gutter-width-large: 24px !default;
