input,
.form-control {
  display: block;
  width: 100%;
  background-color: $color-light-grey;
  border-radius: 5px;
  @include rem(min-height, 30px);
  @include rem(padding, 0 10px);
  box-sizing: border-box;
  border: 1px solid $color-light-grey;
  @include font(16px, 1em, 0.1em);
  font-family: $font-medium;
  color: $color-deep-blue;
  box-shadow: none;
  appearance: none;

  @include for-tablet-portrait-up {
    @include font(13px, 1em, 0.1em);
  }
}

.input-group {
  position: relative;

  .form-control {
    @include rem(padding-right, 40px);
  }

  &--addon {
    position: absolute;
    top: 50%;
    @include rem(right, 10px);
    transform: translateY(-50%);
    pointer-events: none;
    color: $color-blue;

    > * {
      color: $color-blue;
      @include font(13px, 13px);
    }
  }
}

form,
.form {
  input {
    @include font(16px, 16px, 1.3px);

    @include for-tablet-portrait-up {
      @include font(13px, 1em, 0.1em);
    }
  }

  &-wrapper {
    @include rem(margin, 30px 0 15px 0);
  }
}

.mat-form-field {
  width: 100%;

  &.no-pb > .mat-form-field-wrapper {
    padding-bottom: 0 !important;
  }

  &-wrapper {
    @include rem(padding-bottom, 20px);
  }

  &-underline {
    display: none;
    opacity: 0;
    visibility: hidden;
  }

  &-infix > input,
  &-infix > .mat-select,
  &-infix > textarea {
    @extend .form-control;
    flex: 1 1 auto;
    order: 3;
  }

  &-infix {
    display: flex;
    flex-direction: column !important;
    padding: 0;
    margin: 0;
    border: none;
  }

  &-infix > textarea {
    @include rem(min-height, 120px);
    @include rem(padding, 10px);
  }

  &-infix > p {
    display: block;
    font-family: $font-medium;
    order: 2;
    @include font(12px, 12px, 1.2px);
    @include rem(margin-bottom, 10px);
  }

  &-label-wrapper {
    position: static;
    pointer-events: auto;
    flex: 1 1 auto;
    order: 1;
    padding: 0;
    margin: 0;
  }

  &-label {
    display: block !important;
    @include font(14px, 14px, 2.4px);
    font-family: $font-bold;
    text-transform: uppercase;
    color: $color-deep-blue;
    text-overflow: unset;
    white-space: unset;
    position: static;
    @include rem(margin-bottom, 7px);
    transform: none !important;

    @include for-tablet-landscape-up {
      @include font(12px, 12px, 2.4px);
    }

    > * {
      font: inherit;
      color: inherit;
    }
  }

  &-label-multiline {
    display: block;
    @include font(16px, 16px, 1.3px);
    font-family: $font-medium;
    text-transform: none;
    @include rem(padding, 0 0 10px);
    margin: 0;

    @include for-tablet-landscape-up {
      @include font(13px, 13px, 1.3px);
    }
  }

  &-required-marker {
    margin-left: -5px;
    color: $color-deep-blue !important;
  }

  &-invalid input,
  &-invalid textarea,
  &-invalid .mat-select {
    border: 1px solid $color-red;
  }

  &-subscript-wrapper {
    @include rem(margin-top, 5px);
    position: static;
    font-size: inherit;

    .mat-error {
      margin-top: 0;
    }
  }

  &:focus-within,
  &:active {
    .mat-form-field-label {
      color: $color-blue !important;
    }
  }
}

.mat-error {
  display: block;
  @include font(13px, 1em, 0.1em);
  font-family: $font-medium;
  color: $color-red;

  .ng-fa-icon {
    @include font(11px, 11px);
  }

  @include for-tablet-landscape-up {
    @include font(11px, 1em, 0.1em);
  }

  & + .mat-error {
    @include rem(margin-top, 5px);
  }
}

.mat-hint {
  display: block;
  @include font(13px, 1em, 0.1em);
  font-family: $font-semibold;
  color: $color-dark-blue;

  .ng-fa-icon {
    @include font(11px, 11px);
  }

  @include for-tablet-landscape-up {
    @include font(11px, 1em, 0.1em);
  }

  & + .mat-hint {
    @include rem(margin-top, 5px);
  }

  & span {
    cursor: pointer;
  }
}

.mat-form-field-multiline {
  .mat-form-field-infix {
    border-top: 0;
    padding-top: 0 !important;
  }
}

.mat-select {
  @extend .form-control;
  background-color: $color-light-grey;
  border-radius: 5px;
  @include rem(min-height, 30px);
  @include rem(padding, 0 10px);
  box-sizing: border-box;
  border: 1px solid $color-light-grey;
  @include font(16px, 1em, 0.1em);
  font-family: $font-medium;
  color: $color-deep-blue;

  @include for-tablet-landscape-up {
    @include font(13px, 1em, 0.1em);
  }

  &-trigger,
  &-value-text {
    @include rem(min-height, 30px);
    @include rem(line-height, 30px);

    > * {
      font-family: $font-medium;
      color: $color-deep-blue;
    }
  }

  &-arrow {
    background: url(/assets/images/icons/icon-dropdown.svg) 50% 50% no-repeat;
    background-size: cover;
    border: none;
    width: 7px;
    height: 14px;
    margin: 0;
  }
}

.mat-select-panel {
  background-color: $color-alabaster;
  border: 1px solid $color-gallery;
  border-radius: 6px;
  box-shadow: none !important;

  .mat-option {
    @include rem(padding, 0 12px);

    &.mat-selected:not(.mat-option-multiple) {
      background-color: transparent;

      .mat-option-text {
        color: $color-deep-blue;
        font-family: $font-bold;
      }
    }
  }
}

.mat-select-content {
  background-color: transparent;
}

.mat-checkbox {
  .mat-checkbox-ripple {
    display: none;
  }

  .mat-checkbox-layout {
    align-items: flex-start;
    white-space: unset;
  }

  .mat-checkbox-inner-container {
    margin: 0;
    @include rem(margin-right, 10px);
  }

  .mat-checkbox-label {
    @include font(13px, 16px, 1.3px);
    font-family: $font-regular;
    color: $color-deep-blue;
    text-transform: none;
  }

  .mat-checkbox-frame {
    border-color: $color-silver-chalice;
  }

  &.mat-checkbox-checked,
  &.mat-checkbox-indeterminate {
    .mat-checkbox-background {
      background-color: $color-blue;
    }
  }

  &.cdk-focused,
  &.cdk-focused {
    .mat-checkbox-frame {
      border-color: $color-blue;
    }
  }

  &.cdk-focused.mat-checkbox-checked,
  &.cdk-focused.mat-checkbox-indeterminate {
    .mat-checkbox-background {
      background-color: $color-dark-blue;
    }
  }

  @include for-tablet-portrait-up {
    @include font(12px, 12px, 0.1em);
  }
}

.mat-checkbox--as-button.mat-checkbox {
  @include rem(line-height, 30px);

  .mat-checkbox-frame {
    border-color: $color-blue;
  }

  .mat-checkbox-label {
    font-family: $font-bold;
    @include font(12px, 16px, 0.1em);
    text-transform: uppercase;
    text-decoration: none;
    color: $color-blue;
  }
}
