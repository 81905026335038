html,
body {
  width: 100%;
  height: auto;
  min-height: 100vh;
  padding: 0;
  margin: 0;
  font-size: 100%;
  font-family: $font-regular;
  background-color: $color-lighter-grey;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  top: 0 !important;
}

.fill-space {
  flex: 1 1 auto;
}

.is-hidden {
  display: none !important;
  visibility: hidden !important;
  opacity: 0 !important;
}

.is-flex-centered {
  justify-content: center;
}

hr,
hr.divider {
  display: block;
  width: 100%;
  height: 1px;
  border: 0;
  border-bottom: 1px dashed $color-silver-chalice;
}

.is-relative {
  position: relative;
}

.is-clickable {
  cursor: pointer;
}

.is-block {
  display: block;
}

.help-center {
  &__content {
    padding: 20px;
    background-color: whitesmoke;
    margin-top: 5px;
    a {
      display: block;
      text-transform: uppercase;
      letter-spacing: 0.1rem;
      margin-bottom: 5px;
      font-weight: bold;
    }
    p {
      text-transform: uppercase;
    }
  }
}

.welcome__heading {
  .hero__title {
    visibility: hidden;
  }
}

.my-tooltip {
  white-space: pre-line;
}
