@import 'config/variables';
@import 'mixins';

.contact-feedback {
  max-width: 90% !important;

  @include for-tablet-portrait-up {
    max-width: 345px !important;
  }

  @include for-desktop-up {
    max-width: 365px !important;
  }

  .mat-dialog-container {
    @include for-phone-only {
      max-height: 90vh;
    }
  }

  .mat-dialog-header {
    h2 {
      @include rem(margin-top, 10px);
      @include rem(margin-bottom, 7px);

      @include for-tablet-portrait-up {
        @include rem(margin-bottom, 5px);
      }
    }
  }

  .mat-dialog-body {
    @include for-tablet-portrait-up {
      @include rem(padding-top, 4px)
    }

    p {
      margin: 0;
      padding: 0;
      @include rem(font-size, 16px);
      letter-spacing: 0.1em;
      line-height: 1.5;

      @include for-desktop-up {
        @include rem(font-size, 13px);
      }
    }

    p + p {
      @include rem(margin-top, 24px);
    }
  }

  .mat-dialog-footer {
    @include rem(padding-top, 10px);

    @include for-tablet-portrait-up {
      @include rem(padding-top, 25px);
    }

    & > .btn,
    & > .mat-button {
      letter-spacing: 0.2em;
      max-width: 100%;

      @include for-tablet-portrait-up {
        width: auto;
        max-width: 0;
        @include rem(min-width, 255px);
      }

      @include for-desktop-up {
        @include rem(font-size, 11px);
      }
    }
  }
}
