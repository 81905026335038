.mat-autocomplete-panel {
  background-color: $color-alabaster;
  border: 1px solid $color-gallery;
  border-top: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: none !important;
  @include rem(margin-top, -6px);
  z-index: 1;

  @include for-tablet-landscape-up {
    @include rem(margin-top, -7px);
  }

  .mat-option {
    white-space: normal;
    background-color: $color-alabaster;
    // @include rem(line-height, 39px);
    height: auto;
    @include rem(padding, 8px 20px);

    &.mat-active {
      color: $color-blue;
      font-family: $font-bold;
      font-weight: 600;
    }

    &:hover {
      background-color: $color-medium-grey;
    }
  }

  .mat-option.mat-selected:not(.mat-active):not(:hover) {
    background-color: $color-alabaster;
  }

  .mat-option + .mat-option {
    border-top: 1px solid rgba(0, 0, 0, 0.030);
  }

  .mat-option-text {
    @include rem(font-size, 13px);
    @include rem(line-height, 18px);
    @include rem(letter-spacing, 1.2px);
    @include rem(margin-top, 2px);
    text-overflow: unset;
  }
}
