// @source: https://github.com/Dogfalo/materialize/blob/v1-dev/sass/components/_grid.scss

@use "sass:math";

.container {
  margin: 0 auto;
  width: 100%;
  @include rem(padding, 0 $container-gutter);
  box-sizing: border-box;
}

@include for-tablet-portrait-up {
  .container {
    @include rem(max-width, 768px);
    @include rem(padding, 0 $container-gutter-medium);
  }
}

@include for-tablet-landscape-up {
  .container {
    // @include rem(max-width, 1200px);
    @include rem(max-width, 1176px);
    @include rem(padding, 0 $container-gutter-large);
  }
}

.col .row {
  min-height: 1px;
  @include rem(margin-left, (-1 * $gutter-width * 0.5));
  @include rem(margin-right, (-1 * $gutter-width * 0.5));

  @include for-tablet-portrait-up {
    @include rem(margin-left, (-1 * $gutter-width-medium * 0.5));
    @include rem(margin-right, (-1 * $gutter-width-medium * 0.5));
  }

  @include for-tablet-landscape-up {
    @include rem(margin-left, (-1 * $gutter-width-large * 0.5));
    @include rem(margin-right, (-1 * $gutter-width-large * 0.5));
  }
}

// Mixins to eliminate code repitition
@mixin reset-offset {
  margin-left: auto;
  left: auto;
  right: auto;
}

@mixin grid-classes($size, $i, $perc) {
  &.offset-#{$size}#{$i} {
    margin-left: $perc;
  }
  &.pull-#{$size}#{$i} {
    right: $perc;
  }
  &.push-#{$size}#{$i} {
    left: $perc;
  }
}

.row {
  margin-left: auto;
  margin-right: auto;

  @include rem(margin-left, (-1 * ($gutter-width * 0.5)));
  @include rem(margin-right, (-1 * ($gutter-width * 0.5)));

  @include for-tablet-portrait-up {
    @include rem(margin-left, (-1 * ($gutter-width-medium * 0.5)));
    @include rem(margin-right, (-1 * ($gutter-width-medium * 0.5)));
  }

  @include for-tablet-landscape-up {
    @include rem(margin-left, (-1 * ($gutter-width-large * 0.5)));
    @include rem(margin-right, (-1 * ($gutter-width-large * 0.5)));
  }

  // Clear floating children
  &:after {
    content: '';
    display: table;
    clear: both;
  }

  .col {
    float: left;
    box-sizing: border-box;
    @include rem(padding, 0 $gutter-width * 0.5);
    @include rem(margin-bottom, 30px);
    min-height: 1px;
    height: auto;

    &[class*='push-'],
    &[class*='pull-'] {
      position: relative;
    }

    @include for-tablet-portrait-up {
      @include rem(margin-bottom, $gutter-width-medium);
      @include rem(padding, 0 $gutter-width-medium * 0.5);
    }

    @include for-tablet-landscape-up {
      @include rem(padding, 0 $gutter-width-large * 0.5);
      @include rem(margin-bottom, $gutter-width-large);
    }

    $i: 1;
    @while $i <= $num-cols {
      $perc: unquote(math.div(100, math.div($num-cols, $i)) + '%');
      &.s#{$i} {
        width: $perc;
        //@include reset-offset;
      }
      $i: $i + 1;
    }

    $i: 1;
    @while $i <= $num-cols {
      $perc: unquote(math.div(100, math.div($num-cols, $i)) + '%');
      @include grid-classes('s', $i, $perc);
      $i: $i + 1;
    }

    @include for-tablet-portrait-up {
      $i: 1;
      @while $i <= $num-cols {
        $perc: unquote(math.div(100, math.div($num-cols, $i)) + '%');
        &.m#{$i} {
          width: $perc;
          //@include reset-offset;
        }
        $i: $i + 1;
      }

      $i: 1;
      @while $i <= $num-cols {
        $perc: unquote(math.div(100, math.div($num-cols, $i)) + '%');
        @include grid-classes('m', $i, $perc);
        $i: $i + 1;
      }
    }

    @include for-tablet-landscape-up {
      $i: 1;
      @while $i <= $num-cols {
        $perc: unquote(math.div(100, math.div($num-cols, $i)) + '%');
        &.l#{$i} {
          width: $perc;
          //@include reset-offset;
        }
        $i: $i + 1;
      }

      $i: 1;
      @while $i <= $num-cols {
        $perc: unquote(math.div(100, math.div($num-cols, $i)) + '%');
        @include grid-classes('l', $i, $perc);
        $i: $i + 1;
      }
    }

    @include for-desktop-up {
      $i: 1;
      @while $i <= $num-cols {
        $perc: unquote(math.div(100, math.div($num-cols, $i)) + '%');
        &.xl#{$i} {
          width: $perc;
          // @include reset-offset;
        }
        $i: $i + 1;
      }

      $i: 1;
      @while $i <= $num-cols {
        $perc: unquote(math.div(100, math.div($num-cols, $i)) + '%');
        @include grid-classes('xl', $i, $perc);
        $i: $i + 1;
      }
    }
  }

  &.is-flex {
    display: flex;
    flex-wrap: wrap;
  }

  &.is-centered {
    justify-content: center;
  }
}
