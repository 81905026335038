dl {
  display: block;
  margin: 0;
  >* {
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
  }
  dt {
    @extend .h5;
    letter-spacing: 0.1em;
  }
  dd {
    @extend .h4;
    letter-spacing: 0.1em;
    @include rem(margin-bottom, 25px);

    > span {
      display: block;
    }
  }
}

ul {
  margin: 0;
  @include rem(padding-left, 15px);
  li+li {
    @include rem(margin-top, 20px);
  }
}

ul.list-two-columns {
  display: flex;
  flex-direction: column;
  @include rem(font-size, 16px);
  letter-spacing: 0.1em;
  line-height: 1.5;
  @include rem(padding-top, 24px);

  @include for-tablet-landscape-up {
    @include rem(font-size, 13px);
    @include rem(line-height, 20px);
    letter-spacing: 0.1em;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;
    flex-wrap: wrap;
    @include rem(padding-top, 20px);
  }

  &:first-child {
    margin: 0;
    padding: 0;
  }

  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  li {
    flex-basis: 100%;
    max-width: 100%;

    @include for-tablet-landscape-up {
      margin-top: 0;
      flex-basis: 50%;
      max-width: 50%;
      &:nth-child(1n+3) {
        @include rem(margin-top, 16px);
      }
    }
  }
}
