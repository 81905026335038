.object-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

.no-padding-right {
  padding-right: 0 !important;
}

.align-center {
  align-items: center;
}

.align-right {
  float: right;
  margin: 0;
}

.align-left {
  float: left;
  margin: 0;
}

.hide-mobile {
  @include for-phone-only {
    display: none;
  }
  @include for-tablet-portrait-up {
    display: none;
  }
  @include for-tablet-landscape-up {
    display: block;
  }
}

.hide-desktop {
  @include for-tablet-landscape-up {
    display: none;
  }
}
