.resource-content-links {
  & > p:first-of-type {
    margin-top: 0;
    padding-top: 0;
  }

  & > p:last-of-type {
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

.resource-content-links + .resource-content-links {
  @include rem(margin-top, 50px);
}

.resource-content-links__title {
  //color: $color-deep-blue;
  @include themify($themes) {
    color: themed('color-primary');
  }
  font-family: $font-bold;
  @include rem(font-size, 20px);
  display: block;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  margin: 0;
  @include rem(padding, 0 0 22px);
}

.resource-content-links__links {
  list-style: none;
  @include rem(margin, 30px 0 0);
  padding: 0;

  li {
    margin: 0;
    padding: 0;
  }

  li + li {
    @include rem(margin-top, 15px);
  }
}

.resource-content-links__title + .resource-content-links__links {
  @include rem(margin-top, 0);
}
