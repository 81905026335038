.data-preview-action {
  //border-bottom: 1px solid $color-blue;
  @include themify($themes) {
    border-bottom: themed('borderBtm1');
  }
  @include rem(padding-bottom, 34px);
  @include rem(margin-bottom, 20px);

  @include for-tablet-portrait-up {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  &-title {
    //color: $color-deep-blue;
    @include themify($themes) {
      color: themed('color-primary');
    }
    @include rem(font-size, 18px);
    @include rem(letter-spacing, 2px);
    font-family: $font-bold;
    text-transform: uppercase;
    display: block;
    margin: 0;
    padding: 0;
  }

  &-data {
    @include rem(margin-top, 27px);

    @include for-tablet-portrait-up {
      @include rem(margin-top, 32px);
    }

    @include for-tablet-landscape-up {
      @include rem(margin-top, 22px);
    }
  }

  &-button {
    @include rem(margin-top, 10px);
  }
}
