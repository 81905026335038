/* ==========================================================================
   Mixins
   ========================================================================== */

/* Add hover state to button */
@mixin hovered($bg, $border, $color) {

  &:hover:not(:disabled),
  &:active:not(:disabled),
  &.is-active:not(:disabled),
  &:focus {
    background-color: $bg;
    border-color: $border;
    color: $color;
    text-decoration: none;
    @content;
  }
}

/* Add disabled state */
@mixin disabled($bg, $border, $color) {
  &:disabled {
    opacity: 0.4;
    cursor: default;
    @content;
  }
}



/* ==========================================================================
   Base Button styles
   ========================================================================== */

%button {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  @include rem(min-height, 30px);
  @include rem(padding, 7px 15px);
  background-color: $color-grey;
  border: 1px solid $color-grey;
  @include rem(border-radius, 6px);

  // Button text styling
  font-family: $font-bold;
  @include font(14px, 14px, 0.1em);
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;

  transition:
    background-color 0.25s ease-out,
    border-color 0.25s ease-out,
    color 0.25s ease-out;

  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  cursor: pointer;
  outline: none;

  /* background-color, border-color, color */
  @include disabled(#c5d3db, #c5d3db, $color-white);

  .mat-icon {
    @include rem(font-size, 16px);
    @include rem(width, 20px);
    @include rem(height, 20px);
    vertical-align: middle;
  }

  @include for-tablet-portrait-up {
    width: auto;
    min-width: 170px;
    @include rem(padding, 7px 30px);
    @include font(12px, 14px, 0.1em);
  }
}



/* ==========================================================================
   Button variations styles
   ========================================================================== */


/* Default button style */
.btn {
  @extend %button;
  // text-overflow: ellipsis;
  // white-space: nowrap;
}

/* Primary variation button style */
.btn-primary {
  //background-color: $color-blue;
  //border-color: $color-blue;
  //color: $color-white;
  @include themify($themes) {
    background-color: themed('buttonPrimaryBackgroundColor');
    border-color: themed('buttonPrimaryBorderColor');
    color: themed('buttonPrimaryTextColor');
  }

  /* background-color, border-color, color */
  //@include hovered($color-dark-blue, $color-dark-blue, $color-white);
  @include themify($themes) {
    @include hovered(
      themed(buttonPrimaryHoveredBackgroundColor),
      themed(buttonPrimaryHoveredBorderColor),
      themed(buttonPrimaryTextColor)
    );
  }
  /* background-color, border-color, color */
  //@include disabled($color-dark-blue, $color-dark-blue, $color-white);
  @include themify($themes) {
    @include disabled(
        themed(buttonPrimaryHoveredBackgroundColor),
        themed(buttonPrimaryHoveredBorderColor),
        themed(buttonPrimaryTextColor)
    );
  }
}

.btn-secondary {
  //background-color: $color-white;
  //border-color: $color-white;
  //color: $color-blue;
  @include themify($themes) {
    background-color: themed('buttonSecondaryBackgroundColor');
    border-color: themed('buttonSecondaryBorderColor');
    color: themed('buttonSecondaryTextColor');
  }

  /* background-color, border-color, color */
  //@include hovered($color-dark-blue, $color-dark-blue, $color-white);
  @include themify($themes) {
    @include hovered(
        themed(buttonPrimaryHoveredBackgroundColor),
        themed(buttonPrimaryHoveredBorderColor),
        themed(buttonPrimaryTextColor)
    );
  }

  /* background-color, border-color, color */
  //@include disabled($color-dark-blue, $color-dark-blue, $color-white);
  @include themify($themes) {
    @include disabled(
        themed(buttonPrimaryHoveredBackgroundColor),
        themed(buttonPrimaryHoveredBorderColor),
        themed(buttonPrimaryTextColor)
    );
  }
}

.btn-tertiary {
  //background-color: $color-white;
  //border-color: $color-white;
  //color: $color-blue;
  //@include themify($themes) {
  //  background-color: themed('buttonTertiaryBackgroundColor');
  //  color: themed('buttonTertiaryTextColor');
  //}
  @include themify($themes) {
    background-color: themed('buttonTertiaryBackgroundColor');
    border-color: themed('buttonTertiaryBorderColor');
    color: themed('buttonTertiaryTextColor');
  }

  /* background-color, border-color, color */
  //@include hovered($color-dark-blue, $color-dark-blue, $color-white);
  @include themify($themes) {
    @include hovered(
        themed(buttonPrimaryHoveredBackgroundColor),
        themed(buttonPrimaryHoveredBorderColor),
        themed(buttonPrimaryTextColor)
    );
  }

  /* background-color, border-color, color */
  //@include disabled($color-dark-blue, $color-dark-blue, $color-white);
  @include themify($themes) {
    @include disabled(
        themed(buttonPrimaryHoveredBackgroundColor),
        themed(buttonPrimaryHoveredBorderColor),
        themed(buttonPrimaryTextColor)
    );
  }
}

.btn-orange {
  background-color: $color-christine;
  border-color: $color-christine;
  color: $color-white;

  /* background-color, border-color, color */
  @include hovered($color-rich-gold, $color-rich-gold, $color-white);

  /* background-color, border-color, color */
  @include disabled($color-christine, $color-christine, $color-white);
}

/* Outlined variation button style */
.btn-outline {
  background-color: transparent;
  //border-color: $color-blue;
  //color: $color-blue;
  @include themify($themes) {
    border-color: themed('buttonOutlineBackgroundColor');
    color: themed('buttonOutlineColor');
  }
  /* background-color, border-color, color */
  //@include hovered($color-dark-blue, $color-dark-blue, $color-white);
  @include themify($themes) {
    @include hovered(themed('buttonOutlineHoverBackgroundColor'), themed('buttonOutlineHoverColor'), themed('buttonTextColor'));
  }

  /* background-color, border-color, color */
  //@include disabled(transparent, $color-dark-blue, $color-dark-blue);
  @include themify($themes) {
    @include disabled(transparent, themed('buttonOutlineHoverColor'), themed('color-brand'));
  }
}

/* Link variation button style */
.btn-link {
  min-width: auto;
  width: auto;
  background-color: transparent;
  border-color: transparent;
  //color: $color-blue;
  @include themify($themes) {
    color: themed('color-secondary');
  }
  @include rem(line-height, 30px);
  padding: 0;

  /* background-color, border-color, color */
  //@include hovered(transparent, transparent, $color-dark-blue) {
  //  text-decoration: none;
  //}
  @include themify($themes) {
    @include hovered(transparent, transparent, themed('color-brand')) {
      text-decoration: none;
    }
  }
  ;

  /* background-color, border-color, color */
  //@include disabled(transparent, transparent, $color-blue);
  @include themify($themes) {
    @include disabled(transparent, transparent, themed(color-brand));
  }
  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
    text-decoration: none;
  }
}


/* ==========================================================================
   Button group styles
   ========================================================================== */

/* Group buttons horizontally with space between */
.btn-group {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  // (5px * 2) of left/right margin to compesate negative margin
  width: calc(100% + 10px);
  @include rem(margin, -5px);

  .btn {
    width: 100%;
    @include rem(margin, 5px);
  }

  /* Reverse buttons position */
  &--reversed {
    flex-direction: row-reverse;
  }

  /* Let buttons wrap if no space is available */
  &--wrap {
    flex-wrap: wrap;
  }

  /* Let buttons fill available space */
  &--fill {
    .btn {
      min-width: 0%;
      width: 100%;
    }
  }

  &--align-left {
    justify-content: flex-start;
  }

  &--align-right {
    justify-content: flex-end;
  }

  @include for-tablet-portrait-up {
    // (10px * 2) of left/right margin to compesate negative margin
    width: calc(100% + 20px);
    @include rem(margin, -10px);

    .btn {
      width: auto;
      @include rem(min-width, 170px);
      @include rem(margin, 10px);
    }

    &--fill {
      .btn {
        min-width: 0%;
        width: 100%;
      }
    }
  }
}

/* Group buttons vertically with space between */
.btn-group-vertical {
  @extend .btn-group;
  flex-direction: column;

  .btn {
    width: auto;
  }

  /* Reverse buttons position */
  &--reversed {
    flex-direction: column-reverse;
  }
}
