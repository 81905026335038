.section {
  display: block;
  width: 100%;

  & + .section {
    @include rem(margin-top, 90px);
  }

  .row {
    margin-bottom: 0;
  }

  > h2 {
    margin: 0;
  }

  &-header {
    @include rem(margin-bottom, 60px);

    > h2 {
      display: block;
      @extend .h2;
      @include font(13px, 13px, 4px);
      text-align: center;
    }

    > hr {
      display: block;
      width: 100%;
      height: 1px;
      border: none;
      border-bottom: 1px dashed $color-silver-chalice;
      position: relative;
      right: 35%;
    }
  }
}
