.resource-technology {
  display: flex;
  flex-direction: column;
  align-items: center;

  .resource-small {
    @include rem(margin-top, 35px);
    width: 100%;
  }
}

.resource-technology__image {
  display: block;
  @include rem(margin, 40px 0);
  max-width: 100%;
}

.resource-technology__content {
  width: 100%;

  & > *:first-child {
    margin-top: 0;
    padding-top: 0;
  }

  & > *:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  p {
    @include for-tablet-landscape-down {
      @include rem(font-size, 16px);
      letter-spacing: 0.1em;
      line-height: 1.5;
    }
  }
}

.resource-technology__button {
  @include rem(margin-top, 35px);
}
